.whatsapp-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.whatsapp-message {
  display: flex;
  flex-direction: column;
}

.whatsapp-message.outgoing {
  align-items: flex-end;
}

.whatsapp-card {
  max-width: 400px;
  width: 100%;
}

.whatsapp-message.outgoing .whatsapp-card .card {
  background: #fdf6f6;
}

.whatsapp-message.incoming .whatsapp-card .card {
  background: #f2f9fd;
}

.whatsapp-message button {
  width: 100%;
  display: block;
}

.whatsapp-message .section {
  margin-top: 20px;
}

.whatsapp-message img {
  width: 100%;
  height: auto;
}

.whatsapp-filter-form {
  position: relative;
}

.react-datepicker-wrapper {
  min-width: 180px;
  margin-left: 10px;
}

.fixed {
  position: fixed;
  bottom: 20px;
  right: 60px;
}