.modal-main {
    width: 58.5rem;
    height: 38.6875rem;
    max-width: max-content;
    overflow: auto;
    border-radius: 1rem;
    
    .modal-content {
        padding: 2rem;
    }
}
.history-modal-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.history-modal-header {
    .modal-title {
        color: var(--primary-primary-base, #062133);
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.01rem;        
    }
}

.history-modal-close-button {
    .modal-title {
        color: var(--primary-primary-base, #062133);
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.01rem;        
    }
}
.modal-table-container {
    width: 100%;
    overflow: auto;
}
.modal-history-table-container {
    padding: 1.5rem;
    border-radius: 0.25rem;
    border: 1px dashed;
    border-color:  lightgray;
    background: rgba(6, 33, 51, 0.01);
}
.history-table-header {
    .modal-title {
        color: var(--primary-primary-base, #062133);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.01rem;        
    }
}
