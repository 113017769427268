
.table-general-text {
    color: var(--primary-primary-80-t, #FFF);

    /* Base/Paragraph/Paragraph Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.badge-container {
    display: flex;
    height: 3.5625rem;
    padding: 0.875rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.label-success {
    /* width: 6.5rem; */
    flex-shrink: 0;
    color: #070707;
    text-align: center;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.6875rem; /* 110% */
    letter-spacing: 0.01rem;
    margin-top: 0.5rem;
    color: rgba(59, 184, 255, 1);
}
.label-pending {
    /* width: 6.5rem; */
    flex-shrink: 0;
    color: #070707;
    text-align: center;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.6875rem; /* 100% */
    letter-spacing: 0.01rem;
    margin-top: 0.5rem;
    color: var(--green-green-10-t, rgba(29, 209, 156, 1));
}
.label-danger {
    /* width: 6.5rem; */
    flex-shrink: 0;
    color: #070707;
    text-align: center;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.6875rem; /* 110% */
    letter-spacing: 0.01rem;
    margin-top: 0.5rem;
    color: rgb(223, 189, 63);
}
.label-failed {
    /* width: 6.5rem; */
    flex-shrink: 0;
    color: #070707;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 11px; /* 110% */
    letter-spacing: 0.16px;
    margin-top: 0.5rem;
    color: rgba(240,62,62, 1);
}

  
.status-badge-success {
    display: flex;
    height: 1.5rem;
    padding: 0.375rem 0.75rem;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 2rem;
    background: rgba(59, 184, 255, 0.30);
}
.status-badge-pending {
    display: flex;
    height: 1.5rem;
    padding: 0.375rem 0.75rem;
    align-items: center;
    flex-shrink: 0;
    border-radius: 2rem;
    background: var(--green-green-10-t, rgba(29, 209, 156, 0.10));
}
.status-badge-danger {
    display: flex;
    height: 1.5rem;
    padding: 0.375rem 0.75rem;
    align-items: center;
    flex-shrink: 0;
    border-radius: 2rem;
    background: rgba(255, 212, 59, 0.30);
}
.status-badge-failed {
    display: flex;
    height: 1.5rem;
    padding: 0.375rem 0.75rem;
    align-items: center;
    flex-shrink: 0;
    border-radius: 2rem;
    background: rgba(240,62,62, 0.3);
}

  